import { createContext, useContext } from "react";



export const defaultValues = {
  currentStep: 1,
  nextFormStep: () => { },
  prevFormStep: () => { },
  updateFormData: () => { },
  formData: {},
  setCurrentStep: () => { },
  clearFormData: () => { },
  handleInputChange: () => { },
  setDidSkipZuzat: () => { },
  didSkipZuzat: false,
  setTriedSubmit: () => { },
  TriedSubmit: false,
  setIsValidPhone: () => { },
  IsValidPhone: true,
  setDidChooseRecommendedOffer: () => { },
  didChooseRecommendedOffer: false
};

export const MultiStepFormContext = createContext(defaultValues);

export const useMultiStepFormContext = () =>
  useContext(MultiStepFormContext);
