import { useEffect, useState } from "react";
import { MultiStepFormContext } from "./MultiStepFormContext";

const loadFromLocalStorage = (key) => {
  const storedData = localStorage.getItem(key);

  return storedData ? JSON.parse(storedData) : null;
};

const sanitizeData = (data) => {
  if (typeof data !== "object" || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => sanitizeData(item));
  }

  const sanitizedObject = {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      sanitizedObject[key] = sanitizeData(data[key]);
    }
  }
  return sanitizedObject;
};

const saveToLocalStorage = (key, data) => {
  const sanitizedData = sanitizeData(data);
  localStorage.setItem(key, JSON.stringify(sanitizedData));
};

const MultiStepFormProvider = ({ children }) => {

  const [didSkipZuzat, setDidSkipZuzat] = useState(false);
  const [didChooseRecommendedOffer, setDidChooseRecommendedOffer] = useState(false)

  const [TriedSubmit, setTriedSubmit] = useState(false)
  const [IsValidPhone, setIsValidPhone] = useState(false)

  const stepLocalStorageKey = "step_state";
  const [currentStep, setCurrentStep] = useState(
    // loadFromLocalStorage(stepLocalStorageKey) ||
    1);

  const initialFormData = {

    persons: [{
      id: 1,
      gender: '',
      jahrgang: '',
      contract: '',
      filledFiles: false,
      zuzatPackages: ['Gesundheitskonto'],
      model: [],
      franchise: "2500",
      accident: false,
      nationalitat: 'CH',
      AMBULANT_MYFLEX: {
        id: 1,
        price: 0,
        OPTIONUPGRADE: { price: 0, withAccident: false },
        UNFALLDECKUNG: { price: 0, withAccident: false }
      },
      SPITAL_MYFLEX: {
        id: 1,
        price: 0,
        OPTIONUPGRADE: { price: 0, withAccident: false },
        KOSTENBETEILIGUNG: { price: 0, withAccident: false },
        UNFALLDECKUNG: { price: 0, withAccident: false }
      },
      ZAHNPFLEGE: {
        id: 8,
        price: 0,
        OPTIONGD: { price: 0, withAccident: false }
      },
      UTI: {
        id: 38,
        price: 0,
      },
      KTI: {
        id: 5,
        price: 0,
      },
    }],
    personId: 1,
    filledPersonIndex: 0,
  };

  const localStorageKey = "multi_step_form_state";
  const [formData, setFormData] = useState(
    // loadFromLocalStorage(localStorageKey) ||
    initialFormData);

  useEffect(() => {
    saveToLocalStorage(localStorageKey, formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {

    saveToLocalStorage(stepLocalStorageKey, currentStep);
  }, [currentStep]);

  const nextFormStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const setFormStep = (step) => {
    setCurrentStep(step);
  };
  const prevFormStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearFormData = () => {
    localStorage.removeItem(localStorageKey);
    setFormData(initialFormData);
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };



  const ContextValues = {
    nextFormStep,
    setFormStep,
    prevFormStep,
    currentStep,
    updateFormData,
    formData,
    setCurrentStep,
    clearFormData,
    handleInputChange,
    setDidSkipZuzat,
    didSkipZuzat,
    setTriedSubmit,
    TriedSubmit,
    setIsValidPhone,
    IsValidPhone,
    setDidChooseRecommendedOffer,
    didChooseRecommendedOffer
  };

  return (
    <MultiStepFormContext.Provider value={ContextValues}>
      {children}
    </MultiStepFormContext.Provider>
  );
};

export default MultiStepFormProvider;
