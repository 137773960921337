import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import MultiStepFormProvider from "./context/MultiStepForm/MultiStepFormProvider";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/style.css';
import { Box } from "@mui/material";

const Home = lazy(() => import("./screens/Home"));
const Chat = lazy(() => import("./screens/Chat"));

function App() {
  return (
    <MultiStepFormProvider>
      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={
            <Suspense fallback={
              <Box position={'absolute'}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                zIndex={100}
                borderRadius={'25px'}
                backgroundColor={'rgb(255, 255, 255)'}>
                <div className="loadingSpinner"></div>
              </Box>
            }>
              <Home />
            </Suspense>
          } />
          <Route exact path="/chat" element={
            <Suspense fallback={
              <Box position={'absolute'}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                zIndex={100}
                borderRadius={'25px'}
                backgroundColor={'rgb(255, 255, 255)'}>
                <div className="loadingSpinner"></div>
              </Box>
            }>
              <Chat />
            </Suspense>
          } />
        </Routes>

      </BrowserRouter>
    </MultiStepFormProvider>
  );
}

export default App;
